html,
body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

* {
	box-sizing: border-box;
	overflow: visible;
}

#root {
	display: flex;
	flex-direction: column;
	font-size: 1.75em;
	height: 100vh;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@keyframes barout {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

@keyframes fadeout {
	from {
		opacity: 1
	}

	to {
		opacity: 0.5
	}
}

@keyframes glowinblack {
	from {
		text-shadow: 0 0 0.3em rgba(0, 6, 9, 0);
	}

	to {
		text-shadow: 0 0 0.3em rgba(0, 6, 9, 255);
	}
}

@keyframes glowinwhite {
	from {
		text-shadow: 0 0 0.3em rgba(246, 240, 255, 0);
	}

	to {
		text-shadow: 0 0 0.3em rgba(246, 240, 255, 255), 0 0 0.3em rgba(246, 240, 255, 255);
	}
}

@keyframes glowbutton {
	from {
		-webkit-box-shadow: 0 0 .1em .25em rgba(220, 53, 69, 0);
	}

	to {
		-webkit-box-shadow: 0 0 .4em .25em rgba(220, 53, 69, 0.6);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}